import React from 'react'
import girl from '../assets/girl.jpg'

function Sale() {
  return (
    <div class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
        <div class="md:h-80 flex flex-col sm:flex-row bg-gray-900 rounded-lg overflow-hidden">
          {/* <!-- content - start --> */}
          <div class="w-full sm:w-1/2 lg:w-2/5 flex flex-col p-4 sm:p-8">
            <h2 class="text-white text-xl md:text-2xl lg:text-4xl font-bold mb-4">Best Quality,<br />At the lowest prices</h2>

            <p class="max-w-md text-gray-400 mb-8">Partner with us to take your jewelry business to the next level. Contact us today to discover the exceptional quality and competitive pricing of our diamonds. We look forward to working with you.</p>

            <div class="mt-auto">
              <a href="mailto:caratconnection@gmail.com" class="inline-block bg-white hover:bg-gray-100 active:bg-gray-200 focus-visible:ring ring-blue-300 text-gray-800 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3">Contact Us</a>
            </div>
          </div>
          {/* <!-- content - end --> */}

          {/* <!-- image - start --> */}
          <div class="w-full sm:w-1/2 lg:w-3/5 h-48 sm:h-auto order-first sm:order-none bg-gray-700">
            <img src={girl} loading="lazy" alt="Photo by Dom Hill" class="w-full h-full object-cover object-center" />
          </div>
          {/* <!-- image - end --> */}
        </div>
      </div>
    </div>
  )
}

export default Sale