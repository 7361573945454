import React from 'react'
import Constants from '../Constants'

import ring_black_bg from '../assets/bright-diamond-black-bg.jpg'
import earrings_blue_bg from '../assets/earrings-blue-bg.jpg'
import rings_blue_bg from '../assets/rings-blue-bg.jpg'
import { HashLink } from 'react-router-hash-link';

function Hero() {

    return (
        <div class="bg-white pb-6 sm:pb-8 lg:pb-12">
            <header class="border-b mb-8">
                <div class="max-w-screen-2xl flex justify-between items-center px-4 md:px-8 mx-auto">
                    {/* <!-- logo - start --> */}
                    <a href="/" class="inline-flex items-center text-black-800 text-2xl md:text-3xl font-bold gap-2.5" aria-label="logo">


                        💎Carat Connection
                    </a>
                    {/* <!-- logo - end --> */}

                    {/* <!-- nav - start --> */}
                    <nav class="hidden lg:flex gap-12 2xl:ml-16">
                        <HashLink to="#manufacturing" smooth class="text-blue-500 text-lg font-semibold">Manufacturing</HashLink>

                        <HashLink to="#our-offerings" smooth class="text-gray-600 hover:text-blue-500 active:text-blue-700 text-lg font-semibold transition duration-100">
                        Our Offerings
                        </HashLink>
                        <HashLink to="#about-us" smooth class="text-gray-600 hover:text-blue-500 active:text-blue-700 text-lg font-semibold transition duration-100">
                        Who We Are
                        </HashLink>
                        <HashLink to="#contact-us" smooth class="text-gray-600 hover:text-blue-500 active:text-blue-700 text-lg font-semibold transition duration-100">
                        Contact Us
                        </HashLink>

                        {/* <a href="#about-us" class="text-gray-600 hover:text-blue-500 active:text-blue-700 text-lg font-semibold transition duration-100">Who We Are</a>
                        <a href="#contact-us" class="text-gray-600 hover:text-blue-500 active:text-blue-700 text-lg font-semibold transition duration-100">Contact Us</a> */}
                    </nav>
                    {/* <!-- nav - end --> */}

                    {/* <!-- buttons - start --> */}
                    <div class="flex sm:border-l border-r divide-x">
                        <a href="tel:714-614-3695" class="w-12 sm:w-20 md:w-24 h-12 sm:h-20 md:h-24 hidden sm:flex flex-col justify-center items-center hover:bg-gray-100 active:bg-gray-200 transition duration-100 gap-1.5">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                            </svg> */}

                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-800" viewBox="0 0 512 512"><path d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z"/></svg>

                            

                            <span class="hidden sm:block text-gray-500 text-xs font-semibold">Text</span>
                        </a>

                        <a href="mailto: caratconnection@gmail.com" class="w-12 sm:w-20 md:w-24 h-12 sm:h-20 md:h-24 flex flex-col justify-center items-center hover:bg-gray-100 active:bg-gray-200 transition duration-100 gap-1.5">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg> */}

                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-800" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>


                            <span class="hidden sm:block text-gray-500 text-xs font-semibold">Email</span>
                        </a>

                        <button type="button" class="w-12 sm:w-20 md:w-24 h-12 sm:h-20 md:h-24 flex lg:hidden flex-col justify-center items-center hover:bg-gray-100 active:bg-gray-200 transition duration-100 gap-1.5">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-800" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                            </svg>

                            <span class="hidden sm:block text-gray-500 text-xs font-semibold">Menu</span>
                        </button>
                    </div>
                    {/* <!-- buttons - end --> */}
                </div>
            </header>

            <section class="max-w-screen-2xl px-4 md:px-8 mx-auto">
                <div class="flex flex-wrap justify-between mb-8 md:mb-16">
                    <div class="w-full lg:w-1/3 flex flex-col justify-center lg:pt-48 lg:pb-24 mb-6 sm:mb-12 lg:mb-0">
                        <h1 class="text-black-800 text-4xl sm:text-5xl md:text-6xl font-bold mb-4 md:mb-8">Stunning Diamonds.
                            <br />Wholesale prices</h1>

                        <p class="max-w-md text-gray-500 xl:text-lg leading-relaxed">Your go-to source for loose diamonds</p>
                    </div>

                    <div class="w-full lg:w-2/3 flex mb-12 md:mb-16">
                        <div class="bg-gray-100 rounded-lg shadow-lg overflow-hidden relative z-10 top-12 md:top-16 left-12 md:left-16 -ml-12 lg:ml-0">
                            {/* <!-- images - start --> */}
                            <img src={earrings_blue_bg} loading="lazy" alt="Photo by Kaung Htet" class="w-full h-full object-cover object-center" />
                        </div>

                        <div class="bg-gray-100 rounded-lg shadow-lg overflow-hidden">
                            <img src={rings_blue_bg} loading="lazy" alt="Photo by Manny Moreno" class="w-full h-full object-cover object-center" />

                            {/* <!-- images - end --> */}
                        </div>
                    </div>
                </div>

                <div class="flex flex-col md:flex-row justify-end items-center gap-8">
                    {/* <div class="w-64 h-12 flex border rounded-lg overflow-hidden divide-x">
                        <a href="#" class="w-1/3 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 text-gray-500 transition duration-100">Men</a>
                        <a href="#" class="w-1/3 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 text-gray-500 transition duration-100">Women</a>
                        <a href="#" class="w-1/3 flex justify-center items-center hover:bg-gray-100 active:bg-gray-200 text-gray-500 transition duration-100">Teens</a>
                    </div> */}

                    {/* <!-- social - start --> */}
                    <div class="flex justify-center lg:justify-start items-center gap-4">
                        <span class="text-gray-400 text-sm sm:text-base font-semibold tracking-widest uppercase">Social</span>
                        <span class="w-12 h-px bg-gray-200"></span>

                        <div class="flex gap-4">
                            <a href={Constants.INSTAGRAM} target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                                <svg class="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                            </a>

                            <a href={Constants.TWITTER} target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                                <svg class="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                </svg>
                            </a>

                            <a href={Constants.LINKEDIN} target="_blank" class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
                                <svg class="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    {/* <!-- social - end --> */}
                </div>
            </section>
        </div>
    )
}

export default Hero