import React from 'react'
import workers_from_side from '../assets/Factory/workers-at-chairs-side.jpg'
import women_examining from '../assets/Factory/women-examining.jpg'
import woman_using_taupe from '../assets/Factory/lady-using-taupe.jpg'
import men_around_diamonds from '../assets/Factory/men-around-diamond-bags.jpg'


function Manufacturing() {
  return (
    <section id='manufacturing' class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
        <div class="flex justify-between items-center gap-8 mb-4 sm:mb-8 md:mb-12">
          <div class="flex items-center gap-12">
            <h2 class="text-gray-800 text-2xl lg:text-3xl font-bold">Manufacturing</h2>

            <p class="max-w-screen-sm hidden md:block text-gray-500">Our inspired designers skillfully cut, design and innovate with various themes and trends. With the state-of-the-art technology at our manufacturing units and our skilled and experienced workforce, our jewelry comes perfectly crafted to make a lasting impression!</p>
          </div>

          <a href="#" class="inline-block bg-white hover:bg-gray-100 active:bg-gray-200 focus-visible:ring ring-blue-300 border text-gray-500 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-4 md:px-8 py-2 md:py-3">More</a>
        </div>

        <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6 xl:gap-8">
          {/* <!-- image - start --> */}
          <a href="#" class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative">
            <img src={men_around_diamonds} loading="lazy" alt="Photo by Minh Pham" class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200" />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>

            {/* <span class="inline-block text-white text-sm md:text-lg relative ml-4 md:ml-5 mb-3">VR</span> */}
          </a>
          {/* <!-- image - end --> */}

          {/* <!-- image - start --> */}
          <a href="#" class="group h-48 md:h-80 md:col-span-2 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative">
            <img src={workers_from_side} loading="lazy" alt="Photo by Magicle" class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200" />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>

            {/* <span class="inline-block text-white text-sm md:text-lg relative ml-4 md:ml-5 mb-3">Tech</span> */}
          </a>
          {/* <!-- image - end --> */}

          {/* <!-- image - start --> */}
          <a href="#" class="group h-48 md:h-80 md:col-span-2 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative">
            <img src={woman_using_taupe} loading="lazy" alt="Photo by Martin Sanchez" class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200" />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>

            {/* <span class="inline-block text-white text-sm md:text-lg relative ml-4 md:ml-5 mb-3">Dev</span> */}
          </a>
          {/* <!-- image - end --> */}

          {/* <!-- image - start --> */}
          <a href="#" class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative">
            <img src={women_examining} loading="lazy" alt="Photo by Lorenzo Herrera" class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200" />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>

            {/* <span class="inline-block text-white text-sm md:text-lg relative ml-4 md:ml-5 mb-3">Retro</span> */}
          </a>
          {/* <!-- image - end --> */}
        </div>
      </div>
    </section>
  )
}

export default Manufacturing