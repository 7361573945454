import ring_in_cloth from '../assets/ring-in-cloth.jpg'
import rose_ring from '../assets/rose-ring.jpg'
import pear_ring from '../assets/pear-ring.jpg'
import loose_diamonds from '../assets/loose-diamonds.jpg'
import earrings_sparkle_bg from '../assets/earrings-sparkle-bg.jpg'
import rose_earrings_blue_bg from '../assets/rose-earrings-blue-bg.jpg'
import earrings_black_bg from '../assets/earrings-black-bg.jpg'
import loose_black_bg from '../assets/loose-black-bg.jpg'
import two_diamonds from '../assets/two-diamonds.jpg'
import orange_ring from '../assets/orange-bg-ring.jpg'
import ring_foliage from '../assets/ring-foliage.jpg'
import tennis_chain_blue_bg from '../assets/tennis-chain-blue-bg.jpg'
import stacked_rings_blue_bg from '../assets/stacked-rings-blue-bg.jpg'
import three_rings from '../assets/three-rings.jpg'
import chain from '../assets/chain.jpg'
import diamond_bags_side from '../assets/Factory/diamond-bags-side.jpg'
import hpht_lab_diamond from '../assets/Factory/hpht-lab-diamond.jpg'
import hpht_lab_diamond2 from '../assets/Factory/hpht-lab-diamond2.jpg'
import diamond_bags_close from '../assets/Factory/diamond-bags-close.jpg'
import large_diamond from '../assets/Factory/large-diamond.jpg'

import React from 'react'

function Collections() {
  return (
    <section id="our-offerings" class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
        <div class="flex justify-between items-end gap-4 mb-6">
          <h2 class="text-gray-800 text-2xl lg:text-3xl font-bold">Our Offerings</h2>

          {/* <a href="#" class="inline-block bg-white hover:bg-gray-100 active:bg-gray-200 focus-visible:ring ring-blue-300 border text-gray-500 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-4 md:px-8 py-2 md:py-3">Show more</a> */}
        </div>

        <div class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 md:gap-x-6 gap-y-6">
          {/* <!-- product - start --> */}
          <div>
            <a href="#" class="group h-96 block bg-gray-100 rounded-lg overflow-hidden shadow-lg mb-2 lg:mb-3">
              <img src={hpht_lab_diamond2} loading="lazy" alt="Photo by Austin Wade" class="w-full h-full object-cover object-center group-hover:scale-110 transition duration-200" />
            </a>

            <div class="flex flex-col">
              <span class="text-gray-500">Size Options</span>
              <a href="#" class="text-gray-800 hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100">0.005 to 3 Carats</a>
            </div>
          </div>
          {/* <!-- product - end --> */}

          {/* <!-- product - start --> */}
          <div>
            <a href="#" class="group h-96 block bg-gray-100 rounded-lg overflow-hidden shadow-lg mb-2 lg:mb-3">
              <img src={hpht_lab_diamond} loading="lazy" alt="Ring in Cloth" class="w-full h-full object-cover object-center group-hover:scale-110 transition duration-200" />
            </a>

            <div class="flex flex-col">
              <span class="text-gray-500">GIA Certified</span>
              <a href="#" class="text-gray-800 hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100">Large Diamonds are GIA Certified</a>
            </div>
          </div>
          {/* <!-- product - end --> */}

          {/* <!-- product - start --> */}
          <div>
            <a href="#" class="group h-96 block bg-gray-100 rounded-lg overflow-hidden shadow-lg mb-2 lg:mb-3">
              <img src={diamond_bags_side} loading="lazy" alt="Photo by Austin Wade" class="w-full h-full object-cover object-center group-hover:scale-110 transition duration-200" />
            </a>

            <div class="flex flex-col">
              <span class="text-gray-500">Color Options</span>
              <a href="#" class="text-gray-800 hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100">D-H, I-J, K-L</a>
            </div>
          </div>
          {/* <!-- product - end --> */}

          {/* <!-- product - start --> */}
          <div>
            <a href="#" class="group h-96 block bg-gray-100 rounded-lg overflow-hidden shadow-lg mb-2 lg:mb-3">
              <img src={diamond_bags_close} loading="lazy" alt="Photo by Austin Wade" class="w-full h-full object-cover object-center group-hover:scale-110 transition duration-200" />
            </a>

            <div class="flex flex-col">
              <span class="text-gray-500">Clarity Options</span>
              <a href="#" class="text-gray-800 hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100">IF to Pique</a>
            </div>
          </div>
          {/* <!-- product - end --> */}
        </div>
      </div>
    </section>
  )
}

export default Collections