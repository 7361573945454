import Collections from './components/Collections.js';
import Hero from './components/Hero.js'
import AboutUs from './components/AboutUs.js';
import Sale from './components/Sale.js';
import FAQ from './components/FAQ.js';
import Features from './components/Features.js';
import Footer from './components/Footer.js';
import Form from './components/Form.js';
import Manufacturing from './components/Manufacturing.js';
import Header from './components/Header.js';
import Newsletter from './components/Newsletter.js';
import Testimonials from './components/Testimonials.js';
import { BrowserRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function App() {
  return (
    <BrowserRouter>
      <Hero />
      {/* <Header /> */}
      <Manufacturing />
      <Collections />
      <AboutUs />
      <Sale />
      <Form />
      <Footer />
    </BrowserRouter>
  );
}
export default App;
