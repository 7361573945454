import sunset_light from '../assets/sunset-light.jpg'
import React from 'react'

function Content() {
  return (
    <section id='about-us' class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
        <div class="grid md:grid-cols-2 gap-8 lg:gap-12">
          <div>
            <div class="h-64 md:h-auto bg-gray-100 overflow-hidden rounded-lg shadow-lg">
              <img src={sunset_light} loading="lazy" alt="Photo by Martin Sanchez" class="w-full h-full object-cover object-center" />
            </div>
          </div>

          <div class="md:pt-8">
            <p class="text-blue-500 font-bold text-center md:text-left">Who we are</p>

            <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4 md:mb-6">A Cut Above The Rest</h1>

            <p class="text-gray-500 sm:text-lg mb-6 md:mb-8">
              Welcome to our website, where we take pride in providing exceptional diamonds to our customers at wholesale prices. We are a team of experienced diamond manufacturers, dedicated to sourcing and crafting only the finest quality diamonds for jewelers and jewelry retailers.
              <br></br>

              We specialize in offering a wide range of GIA certified and lab-grown diamonds. Our rough diamonds are carefully sourced from the most reputable suppliers and undergo rigorous quality control measures to ensure their authenticity and brilliance.
              <br></br><br></br>
              At our company, we believe in transparency and integrity in all our business dealings. We work closely with our clients to understand their unique needs and preferences, offering customized solutions that meet their exact requirements. Our commitment to customer satisfaction is unwavering, and we always strive to exceed expectations.
              <br></br><br></br>
              Our team of experts has 30+ years of experience in the diamond industry, and we are passionate about what we do. We stay up-to-date with the latest trends and technology to ensure that we are providing our clients with the best possible products and services.
              <br></br><br></br>
              Whether you are a jeweler or a jewelry retailer, we are committed to providing you with the highest quality diamonds at the best possible prices. We believe in building long-term relationships with our clients, and we are dedicated to helping them succeed in their business.
              <br></br><br></br>
              Thank you for considering our company for your diamond needs. We look forward to working with you and providing you with the finest diamonds available.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content